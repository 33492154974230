import React from 'react';
import { Shield, CheckCircle, Award, Users, Globe, BookOpen } from 'lucide-react';

const About: React.FC = () => {
  // Core Values Data
  const coreValues = [
    {
      icon: <Shield className="w-10 h-10 text-purple-600" />,
      title: "Excellence in Security",
      description: "Delivering world-class cybersecurity solutions with unwavering commitment to quality and precision."
    },
    {
      icon: <CheckCircle className="w-10 h-10 text-purple-600" />,
      title: "Certified Expertise",
      description: "Our team comprises certified professionals with extensive experience in global security standards and frameworks."
    },
    {
      icon: <Award className="w-10 h-10 text-purple-600" />,
      title: "Industry Leadership",
      description: "Setting benchmarks in cybersecurity auditing and compliance through innovative approaches and methodologies."
    }
  ];

  // Key Features Data
  const keyFeatures = [
    {
      title: "Comprehensive Service Portfolio",
      points: [
        "ISO Standards Implementation & Consulting",
        "Internal & External Security Audits",
        "Technical Security Assessments",
        "Strategic Advisory Services",
        "Specialized Industry Solutions"
      ]
    },
    {
      title: "Industry Expertise",
      points: [
        "Critical Infrastructure Protection",
        "Financial Services Security",
        "Healthcare Security Solutions",
        "Government Security Compliance",
        "Enterprise Risk Management"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      {/* Hero Section */}
      <section className="relative h-[400px] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-900 via-indigo-800 to-blue-900" />
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />
        <div className="relative max-w-7xl mx-auto px-4 h-full flex items-center">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold text-white mb-6">
              Leading the Way in Cybersecurity Excellence
            </h1>
            <p className="text-xl text-gray-200 leading-relaxed">
              Empowering organizations with comprehensive cybersecurity auditing, compliance solutions, 
              and strategic consulting services to navigate the evolving digital security landscape.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        {/* Mission Section */}
        <section className="mb-20">
          <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12">
            <div className="grid md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Mission</h2>
                <p className="text-lg text-gray-600 mb-6">
                  To provide exceptional cybersecurity audit and consulting services that enable 
                  organizations to build robust security frameworks, achieve compliance, and protect 
                  their critical assets in an increasingly complex threat landscape.
                </p>
                <p className="text-lg text-gray-600">
                  We strive to be the trusted partner for organizations seeking to strengthen their 
                  security posture through comprehensive audits, strategic guidance, and innovative 
                  security solutions.
                </p>
              </div>
              <div className="space-y-6">
                <div className="flex items-center gap-4 p-4 bg-purple-50 rounded-lg">
                  <Globe className="w-8 h-8 text-purple-600" />
                  <div>
                    <h3 className="font-semibold text-gray-900">Global Standards</h3>
                    <p className="text-gray-600">Implementation of international security frameworks</p>
                  </div>
                </div>
                <div className="flex items-center gap-4 p-4 bg-purple-50 rounded-lg">
                  <Users className="w-8 h-8 text-purple-600" />
                  <div>
                    <h3 className="font-semibold text-gray-900">Expert Team</h3>
                    <p className="text-gray-600">Certified professionals with extensive experience</p>
                  </div>
                </div>
                <div className="flex items-center gap-4 p-4 bg-purple-50 rounded-lg">
                  <BookOpen className="w-8 h-8 text-purple-600" />
                  <div>
                    <h3 className="font-semibold text-gray-900">Industry Knowledge</h3>
                    <p className="text-gray-600">Deep understanding of sector-specific requirements</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Core Values Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Our Core Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {coreValues.map((value, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                <div className="mb-6">{value.icon}</div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Key Features Section */}
        <section>
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Our Expertise</h2>
          <div className="grid md:grid-cols-2 gap-12">
            {keyFeatures.map((feature, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-lg">
                <h3 className="text-xl font-semibold text-purple-700 mb-6">{feature.title}</h3>
                <ul className="space-y-4">
                  {feature.points.map((point, pointIndex) => (
                    <li key={pointIndex} className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-purple-600 flex-shrink-0 mt-1" />
                      <span className="text-gray-700">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;