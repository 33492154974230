import React, { useState, useEffect } from 'react';
import { Shield, Lock, FileCheck, Settings, Brain, Globe, ChevronRight, Check } from 'lucide-react';

interface HomeProps {
  setCurrentPage: (page: string) => void;
}

const Home: React.FC<HomeProps> = ({ setCurrentPage }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Key Services
  const keyServices = [
    {
      icon: <Shield className="w-12 h-12 text-purple-600" />,
      title: "Compliance & Standards",
      description: "Implementation of ISO standards and industry-specific frameworks including ISO 27001, ISO 27701, ISO 20000",
      link: "services"
    },
    {
      icon: <FileCheck className="w-12 h-12 text-purple-600" />,
      title: "Audit Services",
      description: "Comprehensive internal, external, and specialized security audits for various management systems",
      link: "services"
    },
    {
      icon: <Lock className="w-12 h-12 text-purple-600" />,
      title: "Security Assessment",
      description: "Technical security assessment including VAPT, architecture review, and risk management",
      link: "services"
    }
  ];

  // Industry Expertise
  const industries = [
    { title: "Healthcare", icon: "🏥" },
    { title: "Financial", icon: "🏦" },
    { title: "Government", icon: "🏛️" },
    { title: "Technology", icon: "💻" },
    { title: "Manufacturing", icon: "🏭" },
    { title: "Retail", icon: "🏪" }
  ];

  // Key Features
  const features = [
    "ISO Standards Implementation & Consulting",
    "Information Security Management System Audits",
    "Vulnerability Assessment and Penetration Testing",
    "Security Program Development",
    "Strategic Advisory Services",
    "Specialized Security Solutions"
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative pt-24 pb-32 bg-gradient-to-br from-purple-900 via-indigo-800 to-blue-900 overflow-hidden">
        <div className="absolute inset-0 bg-black/10"></div>
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />
        <div className="relative max-w-7xl mx-auto px-4">
          <div className={`text-center transform transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
            <div className="inline-block px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm mb-6">
              <span className="text-sm font-medium text-white">Leading Cybersecurity Excellence</span>
            </div>
            <h1 className="text-5xl md:text-7xl font-bold text-white mb-6">
              Cybersecurity Audit & Consultancy Services
            </h1>
            <p className="text-xl md:text-2xl text-gray-200 mb-8 max-w-3xl mx-auto">
              Expert guidance in cybersecurity audits, ISO certifications, and comprehensive security assessments, 
              ensuring robust protection for your organization's digital assets.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => setCurrentPage('services')}
                className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 transition-colors"
              >
                Explore Our Services <ChevronRight className="ml-2" />
              </button>
              <button
                onClick={() => setCurrentPage('contact')}
                className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-purple-700 transition-colors"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Key Services Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Comprehensive Security Solutions</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Delivering expert cybersecurity services to protect your organization and ensure compliance
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {keyServices.map((service, index) => (
              <div 
                key={index} 
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow border border-gray-100"
              >
                <div className="mb-6">{service.icon}</div>
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <button
                  onClick={() => setCurrentPage(service.link)}
                  className="text-purple-600 font-medium hover:text-purple-700 flex items-center"
                >
                  Learn More <ChevronRight className="ml-1" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold text-gray-900 mb-6">
                Why Choose Our Services?
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                We provide comprehensive cybersecurity solutions backed by years of industry experience
                and certified expertise.
              </p>
              <div className="grid gap-4">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
              {industries.map((industry, index) => (
                <div key={index} className="bg-white p-6 rounded-xl text-center shadow-md">
                  <div className="text-4xl mb-3">{industry.icon}</div>
                  <div className="font-medium text-gray-800">{industry.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-purple-900 to-blue-900 text-white">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6">Ready to Secure Your Organization?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Contact us today to discuss how our comprehensive security solutions can protect your business.
          </p>
          <button
            onClick={() => setCurrentPage('contact')}
            className="inline-flex items-center px-8 py-4 border-2 border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-purple-900 transition-colors"
          >
            Schedule a Consultation <ChevronRight className="ml-2" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default Home;