import React, { useState } from 'react';
import { Menu, X, ChevronRight } from 'lucide-react';
import logo from '../assets/images/logo.png';

interface NavigationProps {
  currentPage: string;
  setCurrentPage: (page: string) => void;
}

interface MenuItem {
  id: string;
  label: string;
  description: string;
}

const MENU_ITEMS: MenuItem[] = [
  { 
    id: 'home', 
    label: 'Home',
    description: 'Return to homepage'
  },
  { 
    id: 'services', 
    label: 'Services',
    description: 'Explore our solutions'
  },
  { 
    id: 'about', 
    label: 'About Us',
    description: 'Learn about our company'
  },
  { 
    id: 'contact', 
    label: 'Contact Us',
    description: 'Get in touch with us'
  }
];

const Navigation: React.FC<NavigationProps> = ({ currentPage, setCurrentPage }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to handle page change and scroll to top
  const handlePageChange = (pageId: string) => {
    setCurrentPage(pageId);
    setIsDropdownOpen(false);
    // Smooth scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const Logo: React.FC = () => (
    <div className="flex items-center h-full pl-8">
      <img
        src={logo}
        alt="Grace Technologies Logo"
        className="object-contain h-16 w-80 rounded-xl"
        loading="eager"
        style={{ filter: 'drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15))' }}
      />
    </div>
  );

  const DropdownMenuItem: React.FC<MenuItem & { isActive: boolean }> = ({ 
    id, 
    label, 
    description, 
    isActive 
  }) => (
    <button
      onClick={() => handlePageChange(id)} // Use new handler function
      className={`
        group flex flex-col w-full px-4 py-3 text-left 
        transition-all duration-300 hover:bg-purple-50
        ${isActive ? 'bg-purple-50' : ''}
      `}
    >
      <div className="flex items-center justify-between">
        <span className={`text-base ${isActive ? 'text-purple-700 font-semibold' : 'text-gray-700'}`}>
          {label}
        </span>
        <ChevronRight 
          className={`
            h-4 w-4 transition-all duration-300
            ${isActive 
              ? 'text-purple-700 translate-x-0' 
              : 'text-gray-400 -translate-x-2 opacity-0 group-hover:translate-x-0 group-hover:opacity-100'
            }
          `} 
        />
      </div>
      <span className="text-sm text-gray-500 group-hover:text-purple-600 transition-colors duration-300">
        {description}
      </span>
    </button>
  );

  return (
    <header className="fixed w-full z-50 shadow-lg">
      <div className="bg-gradient-to-r from-white via-purple-700 to-blue-600">
        <div className="max-w-7xl mx-auto h-20">
          <div className="flex justify-between items-center h-full">
            {/* Logo Section */}
            <div className="w-96">
              <button 
                onClick={() => handlePageChange('home')} // Use new handler for logo click
                className="flex items-center h-full"
              >
                <Logo />
              </button>
            </div>

            {/* Title Section */}
            <div className="flex-1 text-center mx-4">
              <h1 className="text-3xl font-bold text-white tracking-wide">
                The Grace Technologies LLC
              </h1>
              <p className="text-sm text-gray-100 font-medium mt-1">
                Vision - Mission - Values
              </p>
            </div>

            {/* Menu Section */}
            <div className="w-96 flex justify-end items-center pr-8">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center text-white hover:bg-white/10 p-2.5 rounded-xl 
                          transition-all duration-300 border border-white/20"
                aria-label="Toggle menu"
              >
                {isDropdownOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>

              {isDropdownOpen && (
                <>
                  <div 
                    className="fixed inset-0 z-40 bg-black/20 backdrop-blur-sm"
                    onClick={() => setIsDropdownOpen(false)}
                  />
                  <div className="absolute right-8 top-full mt-2 w-64 bg-white rounded-2xl 
                                shadow-2xl z-50 overflow-hidden divide-y divide-gray-100">
                    {MENU_ITEMS.map((item) => (
                      <DropdownMenuItem 
                        key={item.id}
                        {...item}
                        isActive={currentPage === item.id}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;