import React, { useState } from 'react';
import { Shield, Lock, FileCheck, Cog, Brain, Globe, ChevronRight } from 'lucide-react';

interface ServiceSection {
  title: string;
  items: string[];
}

interface ServiceDetail {
  title: string;
  description: string;
  sections: ServiceSection[];
}

interface ServiceDetails {
  [key: string]: ServiceDetail;
}

const Services: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState('compliance');

  // Main navigation categories
  const categories = [
    {
      id: 'compliance',
      icon: <Shield className="w-6 h-6" />,
      title: 'Compliance & Standards',
      subtitle: 'Implementation & Consulting'
    },
    {
      id: 'audit',
      icon: <FileCheck className="w-6 h-6" />,
      title: 'Audit Services',
      subtitle: 'Internal & External Audits'
    },
    {
      id: 'assessment',
      icon: <Lock className="w-6 h-6" />,
      title: 'Security Assessment',
      subtitle: 'Technical & Risk Assessment'
    },
    {
      id: 'program',
      icon: <Cog className="w-6 h-6" />,
      title: 'Program Development',
      subtitle: 'Security & Policy Development'
    },
    {
      id: 'advisory',
      icon: <Brain className="w-6 h-6" />,
      title: 'Advisory Services',
      subtitle: 'Strategic Consulting'
    },
    {
      id: 'specialized',
      icon: <Globe className="w-6 h-6" />,
      title: 'Specialized Security',
      subtitle: 'Industry Solutions'
    }
  ];

  // Detailed service information
  const serviceDetails: ServiceDetails = {
    compliance: {
      title: "Compliance & Standards Implementation",
      description: "Comprehensive consulting services for international standards and industry-specific frameworks",
      sections: [
        {
          title: "ISO Standards Consulting",
          items: [
            "ISO 27001 Information Security Management System (ISMS)",
            "ISO 27701 Privacy Information Management System (PIMS)",
            "ISO 20000 Information Technology Service Management (ITSM)",
            "ISO 42001 AI Management System",
            "ISO 22301 Business Continuity Management",
            "ISO 31000 Risk Management Framework",
            "ISO 9001 Quality Management System (QMS)",
            "Develop control self-assessment (CSA) based on ISO management system standards"
          ]
        },
        {
          title: "Industry-Specific Frameworks",
          items: [
            "NIST Cybersecurity Framework Implementation",
            "CIS Controls Implementation",
            "SOC 2 Compliance Readiness",
            "GDPR Compliance Assessment and Implementation",
            "PCI DSS Compliance (Payment Card Industry)"
          ]
        }
      ]
    },
    audit: {
      title: "Audit Services",
      description: "Comprehensive audit solutions covering internal, external, and specialized assessments",
      sections: [
        {
          title: "Internal Audits",
          items: [
            "ISO 27001 Internal Audits",
            "ISO 22301 Internal Audits",
            "Information Security Management System Audits",
            "Privacy Information Management System Audits",
            "Network Security Configuration Audits",
            "Access Control Audits",
            "Data Protection Audits",
            "Business Continuity Management Audits",
            "IS General Controls Audit",
            "Specific Information Systems Audits"
          ]
        },
        {
          title: "External & Certification Audits",
          items: [
            "Pre-certification Audits for Management System Standards",
            "Stage 1 & Stage 2 Certification Support",
            "Surveillance Audit Support",
            "Re-certification Audit Support",
            "Second Party Audits of Management Systems",
            "Third-party Audit Management",
            "Certification Gap Analysis / Assessment",
            "Audit Evidence Collection and Documentation",
            "Corrective Action Plan Development"
          ]
        },
        {
          title: "Specialized Audits",
          items: [
            "Cloud Security Audits",
            "Application Security Audits",
            "Supply Chain Security Audits",
            "Physical Security Audits",
            "IT General Controls (ITGC) Audits",
            "Operational Technology (OT) Security Audits",
            "Compliance Program Audits",
            "Security Architecture Audits"
          ]
        }
      ]
    },
    assessment: {
      title: "Security Assessment Services",
      description: "Technical security assessment and risk management solutions for comprehensive protection",
      sections: [
        {
          title: "Technical Security Assessment",
          items: [
            "Vulnerability Assessment and Penetration Testing (VAPT) for IT Networks",
            "VAPT for Applications",
            "Network Security Architecture Review",
            "Cloud Security Assessment",
            "Application Security Testing",
            "Source Code Reviews",
            "Mobile Application Security Assessment",
            "IoT Security Assessment"
          ]
        },
        {
          title: "Risk Assessment & Management",
          items: [
            "Enterprise Risk Assessment",
            "Third-Party Vendor Risk Assessment",
            "Security Gap Analysis",
            "Data Privacy Impact Assessment",
            "Business Impact Analysis",
            "Threat Modeling"
          ]
        }
      ]
    },
    program: {
      title: "Security Program Development",
      description: "Comprehensive security program development and implementation services",
      sections: [
        {
          title: "Policy & Governance",
          items: [
            "Information Security Policy Development",
            "Security Procedures and Standards Creation",
            "Security Awareness Training Program",
            "Incident Response Planning",
            "Data Classification and Handling Guidelines",
            "Access Control Policy Development"
          ]
        },
        {
          title: "Security Architecture",
          items: [
            "Zero Trust Architecture Design",
            "Cloud Security Architecture",
            "Network Security Design",
            "Identity and Access Management (IAM) Strategy",
            "Secure SDLC Implementation",
            "Security Controls Framework Development"
          ]
        }
      ]
    },
    advisory: {
      title: "Advisory Services",
      description: "Strategic consulting and comprehensive training solutions for enhanced security posture",
      sections: [
        {
          title: "Strategic Consulting",
          items: [
            "CISO-as-a-Service",
            "Security Roadmap Development",
            "Security Budget Planning",
            "Security Program Maturity Assessment",
            "Digital Transformation Security",
            "Merger & Acquisition Security Assessment"
          ]
        },
        {
          title: "Training & Awareness",
          items: [
            "Executive Security Awareness",
            "Employee Security Training",
            "IT / OT Technical Personnel Security Training",
            "Privacy Requirements Training",
            "Compliance Training",
            "Social Engineering Awareness",
            "Security Champions Program Development"
          ]
        }
      ]
    },
    specialized: {
      title: "Specialized Security Services",
      description: "Advanced security solutions for emerging technologies and industry-specific requirements",
      sections: [
        {
          title: "Emerging Technology Security",
          items: [
            "Blockchain Security Assessment",
            "AI/ML Security Review",
            "5G Security Assessment",
            "DevSecOps Implementation",
            "Container Security",
            "Microservices Security"
          ]
        },
        {
          title: "Industry-Specific Solutions",
          items: [
            "Critical Infrastructure Protection",
            "Industrial Control Systems (ICS) Security",
            "Financial Services Security",
            "Healthcare Security Solutions",
            "Retail Security Solutions",
            "Government Security Solutions"
          ]
        }
      ]
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Premium Gradient */}
      <section className="relative h-96 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-900 via-indigo-800 to-blue-900" />
        <div 
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />
        <div className="relative max-w-7xl mx-auto px-4 h-full flex items-center">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold text-white mb-6">
              Cybersecurity Audit & Consultancy Services
            </h1>
            <p className="text-xl text-gray-200 leading-relaxed">
              Comprehensive security solutions for modern enterprises, ensuring compliance,
              protection, and strategic security advancement.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="max-w-7xl mx-auto px-4 py-16">
        {/* Service Category Navigation */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-12">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`
                p-6 rounded-xl transition-all duration-300 text-left
                ${activeCategory === category.id 
                  ? 'bg-white shadow-lg scale-105 border-t-4 border-purple-600' 
                  : 'bg-white/50 hover:bg-white hover:shadow'}
              `}
            >
              <div className={`
                p-3 rounded-lg inline-block mb-3
                ${activeCategory === category.id 
                  ? 'bg-purple-50 text-purple-600' 
                  : 'bg-gray-100 text-gray-600'}
              `}>
                {category.icon}
              </div>
              <h3 className="font-semibold text-gray-900 mb-1">{category.title}</h3>
              <p className="text-sm text-gray-500">{category.subtitle}</p>
            </button>
          ))}
        </div>

        {/* Service Details Section */}
        {serviceDetails[activeCategory] && (
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                {serviceDetails[activeCategory].title}
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                {serviceDetails[activeCategory].description}
              </p>

              {serviceDetails[activeCategory].sections.map((section, index) => (
                <div key={index} className="mb-10 last:mb-0">
                  <h3 className="text-xl font-semibold text-purple-700 mb-6">
                    {section.title}
                  </h3>
                  <div className="grid gap-4">
                    {section.items.map((item, itemIndex) => (
                      <div 
                        key={itemIndex}
                        className="flex items-center p-4 bg-gray-50 rounded-lg hover:bg-purple-50 transition-colors"
                      >
                        <ChevronRight className="w-5 h-5 text-purple-500 mr-3 flex-shrink-0" />
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Services;