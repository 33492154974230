import React from 'react';
import { Phone, Mail } from 'lucide-react';

interface FooterProps {
  setCurrentPage: (page: string) => void;
}

const Footer: React.FC<FooterProps> = ({ setCurrentPage }) => {
  return (
    <footer className="bg-gradient-to-r from-purple-800 via-purple-700 to-purple-600 text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4">The Grace Technologies</h3>
            <p className="text-lg">Secured with Grace</p>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-3">
              <li>
                <a href="#services" onClick={() => setCurrentPage('services')} className="text-lg hover:text-purple-200 transition duration-200">
                  Services
                </a>
              </li>
              <li>
                <a href="#about" onClick={() => setCurrentPage('about')} className="text-lg hover:text-purple-200 transition duration-200">
                  About Us
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setCurrentPage('contact')} className="text-lg hover:text-purple-200 transition duration-200">
                  Contact
                </a>
              </li>
              <li>
                <a href="#blog" onClick={() => setCurrentPage('blog')} className="text-lg hover:text-purple-200 transition duration-200">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4">Services</h4>
            <ul className="space-y-3 text-lg">
              <li>Strategic Security</li>
              <li>Threat Management</li>
              <li>Cloud Security</li>
              <li>Compliance Services</li>
            </ul>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4">Contact</h4>
            <div className="space-y-3">
              <p className="flex items-center text-lg">
                <Phone size={20} className="mr-2" /> +968 7814-5416
              </p>
              <p className="flex items-center text-lg">
                <Mail size={20} className="mr-2" /> info@thegrace.company
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-purple-500 text-center">
          <p className="text-lg">&copy; 2024 The Grace Technologies LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;