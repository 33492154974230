import React, { useState } from 'react';

interface BlogPost {
  id: number;
  title: string;
  category: string;
  author: string;
  role: string;
  date: string;
  readTime: string;
  image: string;
  excerpt: string;
  tags: string[];
}

interface FeaturedPost extends BlogPost {
  category: string;
}

const Blog: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const categories = [
    { id: 'all', name: 'All Posts' },
    { id: 'security-insights', name: 'Security Insights' },
    { id: 'industry-news', name: 'Industry News' },
    { id: 'best-practices', name: 'Best Practices' },
    { id: 'thought-leadership', name: 'Thought Leadership' },
    { id: 'case-studies', name: 'Case Studies' }
  ];

  const blogPosts: BlogPost[] = [
    {
      id: 1,
      title: "The Future of AI in Cybersecurity: Trends and Predictions",
      category: "security-insights",
      author: "David Chen",
      role: "Chief Executive Officer",
      date: "March 15, 2024",
      readTime: "5 min read",
      image: "/api/placeholder/800/400",
      excerpt: "Explore how artificial intelligence is revolutionizing the cybersecurity landscape and what organizations can expect in the coming years.",
      tags: ["AI", "Machine Learning", "Security Trends"]
    },
    {
      id: 2,
      title: "Implementing Zero Trust Architecture: A Comprehensive Guide",
      category: "best-practices",
      author: "Sarah Williams",
      role: "Chief Technology Officer",
      date: "March 10, 2024",
      readTime: "8 min read",
      image: "/api/placeholder/800/400",
      excerpt: "Learn the essential steps and best practices for implementing a zero trust architecture in your organization.",
      tags: ["Zero Trust", "Security Architecture", "Implementation"]
    },
    {
      id: 3,
      title: "Case Study: Financial Institution's Security Transformation",
      category: "case-studies",
      author: "Michael Rodriguez",
      role: "Chief Security Officer",
      date: "March 5, 2024",
      readTime: "6 min read",
      image: "/api/placeholder/800/400",
      excerpt: "How a leading financial institution strengthened its security posture and achieved compliance goals.",
      tags: ["Finance", "Case Study", "Compliance"]
    },
    {
      id: 4,
      title: "Emerging Threats in Cloud Security: What You Need to Know",
      category: "industry-news",
      author: "Emily Thompson",
      role: "Head of Research",
      date: "March 1, 2024",
      readTime: "7 min read",
      image: "/api/placeholder/800/400",
      excerpt: "An analysis of the latest threats targeting cloud infrastructure and strategies for protection.",
      tags: ["Cloud Security", "Threats", "Risk Management"]
    }
  ];

  const featuredPost: FeaturedPost = {
    id: 0,
    title: "2024 Cybersecurity Landscape: Navigating New Challenges",
    category: "thought-leadership",
    author: "David Chen",
    role: "Chief Executive Officer",
    date: "March 20, 2024",
    readTime: "10 min read",
    image: "/api/placeholder/1200/600",
    excerpt: "A comprehensive analysis of the evolving cybersecurity landscape and strategic approaches for organizations to stay ahead of threats.",
    tags: ["Cybersecurity", "Strategy", "Innovation"]
  };

  const filteredPosts = blogPosts.filter(post => {
    const matchesCategory = selectedCategory === 'all' || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="pt-16 min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="bg-gradient-to-br from-purple-700 to-blue-500 text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">Security Insights & Updates</h1>
            <p className="text-xl">
              Expert perspectives on cybersecurity trends, best practices, and industry insights
            </p>
          </div>
        </div>
      </section>

      {/* Featured Post */}
      <div className="max-w-7xl mx-auto px-4 -mt-12">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="md:flex">
            <div className="md:w-1/2">
              <img 
                src={featuredPost.image} 
                alt={featuredPost.title}
                className="h-64 md:h-full w-full object-cover"
              />
            </div>
            <div className="md:w-1/2 p-8">
              <div className="uppercase tracking-wide text-sm text-purple-700 font-semibold mb-2">
                Featured Article
              </div>
              <h2 className="text-2xl font-bold mb-4">{featuredPost.title}</h2>
              <p className="text-gray-600 mb-4">{featuredPost.excerpt}</p>
              <div className="flex items-center mb-4">
                <div className="text-sm">
                  <p className="font-medium">{featuredPost.author}</p>
                  <p className="text-gray-500">{featuredPost.date} · {featuredPost.readTime}</p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2">
                {featuredPost.tags.map((tag, index) => (
                  <span 
                    key={index}
                    className="px-3 py-1 bg-purple-50 text-purple-700 text-sm rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blog Content */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Filters */}
        <div className="mb-8 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          {/* Categories */}
          <div className="flex flex-wrap gap-2">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  selectedCategory === category.id
                    ? 'bg-purple-700 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>

          {/* Search */}
          <div className="w-full md:w-64">
            <input
              type="text"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
            />
          </div>
        </div>

        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map(post => (
            <div key={post.id} className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
              <img 
                src={post.image} 
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <div className="uppercase tracking-wide text-sm text-purple-700 font-semibold mb-2">
                  {categories.find(cat => cat.id === post.category)?.name}
                </div>
                <h3 className="text-xl font-bold mb-2">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center justify-between mb-4">
                  <div className="text-sm">
                    <p className="font-medium">{post.author}</p>
                    <p className="text-gray-500">{post.date} · {post.readTime}</p>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  {post.tags.map((tag, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-purple-50 text-purple-700 text-sm rounded-full"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;